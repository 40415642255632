import React, { FC, useMemo, useRef, useState } from 'react';
import AssetCard from '../AssetCard';
import { MediaAsset } from 'app/modules/mediaLibrary/types';
import NoAssetsFound from './NoAssetsFound';
import ArrayUtils from 'app/utils/array';
import Skeleton from 'react-loading-skeleton';
import {
  Icon,
  LightBox,
  LightBoxInstance,
  mergeTailwindClasses,
} from '@kontentino/ui';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { faCloudArrowUp } from '@fortawesome/pro-solid-svg-icons/faCloudArrowUp';
import MediaLibraryConfig from 'app/modules/mediaLibrary/config';

type Props = {
  isLoading: boolean;
  assets: MediaAsset[];
  className?: string;
  handleSelectedIds?: (assetIds: string[]) => void;
  onUpload?: (files: File[]) => void;
};

const AssetsWrapper: FC<Props> = ({
  assets,
  isLoading,
  className,
  handleSelectedIds,
  onUpload,
}) => {
  const skeletons = ArrayUtils.generate(6);
  const lightbox = useRef<LightBoxInstance>();
  const dropzone = useDropzone({
    onDrop: onUpload,
    maxFiles: MediaLibraryConfig.MAX_SELECTED_ITEMS_TO_UPLOAD,
    disabled: isLoading,
  });

  const [selectedAssetsIds, setSelectedAssetsIds] = useState<string[]>([]);

  const handleSelect = (assetId: string) => {
    setSelectedAssetsIds((prevSelected) => {
      let newSelected;
      if (prevSelected.includes(assetId)) {
        newSelected = prevSelected.filter((id) => id !== assetId);
      } else {
        newSelected = [...prevSelected, assetId];
      }
      handleSelectedIds && handleSelectedIds(newSelected);
      return newSelected;
    });
  };

  return (
    <>
      {!isLoading && assets?.length === 0 && <NoAssetsFound />}
      <div
        className={mergeTailwindClasses(
          clsx(
            'tw-grid tw-grid-flow-row tw-grid-cols-[repeat(auto-fill,_minmax(210px,1fr))] tw-gap-4',
            className,
          ),
        )}
      >
        {isLoading &&
          assets.length === 0 &&
          skeletons.map((i) => (
            <Skeleton height={210} key={i} className="tw-aspect-[4/3]" />
          ))}

        {onUpload && (
          <div
            className={
              'tw-flex tw-aspect-[4/3] tw-cursor-pointer tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-rounded tw-border tw-border-dashed tw-border-primary-100 tw-bg-white tw-text-sm tw-font-semibold tw-text-primary-100 hover:tw-bg-primary-10'
            }
            onClick={dropzone.open}
            {...dropzone.getRootProps()}
          >
            <input {...dropzone.getInputProps()} />
            <Icon icon={faCloudArrowUp} size="xl" />
            <div className="tw-flex tw-flex-col tw-items-center">
              <span>Click to upload</span>
              <span className="tw-text-grayscale-180">or drag and drop</span>
            </div>
          </div>
        )}

        {assets.map((asset, index) => (
          <AssetCard
            key={asset.id}
            asset={asset}
            onPreviewClick={() => lightbox.current?.openGallery(index)}
            onSelect={handleSelect}
            isSelected={selectedAssetsIds.includes(asset.id)}
          />
        ))}
      </div>

      {useMemo(
        () =>
          !!assets.length && (
            <LightBox
              galleryId="assets-lightbox"
              dynamic
              dynamicEl={assets.map((asset) => ({
                iframe: asset.mime_type.includes('application/pdf'),
                src: asset.url,
                thumb: asset.thumbnails[0].url ?? asset.url,
                slideName: asset.original_filename,
                download: true,
              }))}
              onInit={({ instance }) => (lightbox.current = instance)}
            />
          ),
        [assets, lightbox],
      )}
    </>
  );
};

export default AssetsWrapper;
