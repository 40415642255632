import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faGif } from '@fortawesome/pro-regular-svg-icons/faGif';
import { faImage } from '@fortawesome/pro-regular-svg-icons/faImage';
import { faVideo } from '@fortawesome/pro-regular-svg-icons/faVideo';
import { DropdownMenu, Button, TextInput, Icon } from '@kontentino/ui';
import { useHistoryQueryParams } from 'app/hooks/useHistoryQueryParams';
import React, { useState } from 'react';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import Logger from 'utils/logger';

const TYPE_OPTIONS = [
  { value: 'images', label: 'Images', icon: faImage },
  { value: 'videos', label: 'Videos', icon: faVideo },
  { value: 'gifs', label: 'Gifs', icon: faGif },
];
const SIZES_OPTIONS = [
  { value: 'small', label: 'Small' },
  { value: 'medium', label: 'Medium' },
  { value: 'large', label: 'Large' },
];
const ORIENTATIONS_OPTIONS = [
  { value: 'horizontal', label: 'Horizontal' },
  { value: 'vertical', label: 'Vertical' },
  { value: 'square', label: 'Square' },
];
const SORT_OPTIONS = [
  {
    key: 'name',
    label: 'Alphabetically',
  },
  {
    key: 'updated_at',
    label: 'Date of last update',
  },
];

export type FiltersData = {
  sortBy: { key: string; label: string };
  selectedType: { value: string; label: string };
  selectedSize: { value: string; label: string };
  selectedOrientation: { value: string; label: string };
};

type Props = {
  onFiltersChange: (data: FiltersData) => void;
};

const AssetsFilters: React.FC<Props> = ({ onFiltersChange }) => {
  const initialFilters = {
    sortBy: { key: 'name', label: 'Alphabetically' },
    selectedType: { value: 'all', label: 'All types' },
    selectedSize: { value: 'all', label: 'All sizes' },
    selectedOrientation: { value: 'all', label: 'All orientations' },
  };
  const history = useHistoryQueryParams();

  const [localFilters, setLocalFilters] = useState(initialFilters);

  const handleFilterChange = (
    newFilter: Partial<FiltersData>,
    param: keyof FiltersData,
  ) => {
    Logger.log('newFilter', newFilter);
    const updatedFilters = { ...localFilters, ...newFilter };
    setLocalFilters(updatedFilters);

    const filterValue = newFilter[param]?.label || '';
    history.updateQueryParameter(param, filterValue);
    onFiltersChange(updatedFilters);
  };

  const resetFilters = (filterType: keyof FiltersData) => {
    Logger.log('resetFilters', filterType);
    const updatedFilters = { ...localFilters };
    setLocalFilters({
      ...localFilters,
      [filterType]: initialFilters[filterType],
    });

    onFiltersChange(updatedFilters);
    history.deleteQueryParameter(filterType);
  };

  const mediaQueryParams = [
    'sortBy',
    'selectedType',
    'selectedSize',
    'selectedOrientation',
  ];

  //TODO: remove sorting by
  useEffectOnce(() => {
    const params = history.getQueryParameters(mediaQueryParams);
    Logger.log('params', params);
    setLocalFilters({
      sortBy:
        SORT_OPTIONS.find((option) => option.label === params.sortBy) ||
        initialFilters.sortBy,
      selectedType:
        TYPE_OPTIONS.find((option) => option.label === params.selectedType) ||
        initialFilters.selectedType,
      selectedSize:
        SIZES_OPTIONS.find((option) => option.label === params.selectedSize) ||
        initialFilters.selectedSize,
      selectedOrientation:
        ORIENTATIONS_OPTIONS.find(
          (option) => option.label === params.selectedOrientation,
        ) || initialFilters.selectedOrientation,
    });
  });

  return (
    <div className="tw-flex tw-w-full  tw-justify-between tw-gap-3">
      <div className="tw-mr-3">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button variant="plain">
              <TextInput
                value={localFilters.sortBy.label}
                iconAfter={<Icon icon={faChevronDown} />}
              />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content className="tw-z-10" align="start">
              {SORT_OPTIONS.map((type) => (
                <DropdownMenu.Item
                  key={`sort-option-${type.key}`}
                  onClick={() => handleFilterChange({ sortBy: type }, 'sortBy')}
                  data-name={`sort_option-${type.label}`}
                  data-cy={`sort_option-${type.label}`}
                  className="tw-capitalize"
                >
                  <span className="tw-font-medium">{type.label}</span>
                </DropdownMenu.Item>
              ))}
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
      <div className="tw-flex tw-gap-3">
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button variant="plain">
              <TextInput
                value={localFilters.selectedType.label}
                iconAfter={<Icon icon={faChevronDown} />}
                className="tw-cursor-pointer"
              />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content className="tw-z-10" align="start">
              {TYPE_OPTIONS.map((type) => (
                <DropdownMenu.Item
                  key={`type-option-${type.value}`}
                  onClick={() =>
                    handleFilterChange({ selectedType: type }, 'selectedType')
                  }
                  data-name={`type_option-${type.label}`}
                  data-cy={`type_option-${type.label}`}
                  className="tw-capitalize"
                  selected={localFilters.selectedType.value === type.value}
                >
                  {type.icon && <Icon icon={type.icon} />}
                  <span className="tw-font-medium">{type.label}</span>
                </DropdownMenu.Item>
              ))}

              <DropdownMenu.Separator />
              <DropdownMenu.Item
                onClick={() => resetFilters('selectedType')}
                data-name="posts-filters-clear-all"
                data-cy="posts-filter-dropdown-option-clear-filters"
              >
                <span className="tw-w-full tw-text-right tw-text-md tw-font-medium">
                  Clear filter
                </span>
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>

        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button variant="plain">
              <TextInput
                value={localFilters.selectedSize.label}
                iconAfter={<Icon icon={faChevronDown} />}
              />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content className="tw-z-10" align="start">
              {SIZES_OPTIONS.map((size) => (
                <DropdownMenu.Item
                  key={`size-option-${size.value}`}
                  onClick={() =>
                    handleFilterChange({ selectedSize: size }, 'selectedSize')
                  }
                  data-name={`size_option-${size.label}`}
                  data-cy={`size_option-${size.label}`}
                  className="tw-capitalize"
                >
                  <span className="tw-font-medium">{size.label}</span>
                </DropdownMenu.Item>
              ))}
              <DropdownMenu.Separator />
              <DropdownMenu.Item
                onClick={() => resetFilters('selectedSize')}
                data-name="posts-filters-clear-all"
                data-cy="posts-filter-dropdown-option-clear-filters"
              >
                <span className="tw-w-full tw-text-right tw-text-md tw-font-medium">
                  Clear filter
                </span>
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>

        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button variant="plain">
              <TextInput
                value={localFilters.selectedOrientation.label}
                iconAfter={<Icon icon={faChevronDown} />}
              />
            </Button>
          </DropdownMenu.Trigger>

          <DropdownMenu.Portal>
            <DropdownMenu.Content className="tw-z-10" align="start">
              {ORIENTATIONS_OPTIONS.map((orientation) => (
                <DropdownMenu.Item
                  key={`orientation-option-${orientation.value}`}
                  onClick={() =>
                    handleFilterChange(
                      { selectedOrientation: orientation },
                      'selectedOrientation',
                    )
                  }
                  data-name={`orientation_option-${orientation.label}`}
                  data-cy={`orientation_option-${orientation.label}`}
                  className="tw-capitalize"
                >
                  <span className="tw-font-medium">{orientation.label}</span>
                </DropdownMenu.Item>
              ))}
              <DropdownMenu.Separator />
              <DropdownMenu.Item
                onClick={() => resetFilters('selectedOrientation')}
                data-name="posts-filters-clear-all"
                data-cy="posts-filter-dropdown-option-clear-filters"
              >
                <span className="tw-w-full tw-text-right tw-text-md tw-font-medium">
                  Clear filter
                </span>
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      </div>
    </div>
  );
};

export default AssetsFilters;
