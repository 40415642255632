import {
  RunningUpload,
  RunningUploadsRecord,
  UploadSequence,
} from 'types/RunningUpload';
import { RunningUploadStatus } from 'constants/runningUpload';
import { v4 } from 'uuid';
import { MediaApi } from 'api/media';
import {
  imageToMediaLibraryItem,
  unprocessedVideoToMediaLibraryItem,
} from 'utils/mediaLibrary';
import Logger from 'utils/logger';
import axios from 'axios';
import { ApiClientError } from 'api/client';
import { CreateRunningUploadsReturn } from 'app/utils/runningUploads';
import { MediaLibraryApi } from 'app/modules/mediaLibrary/api/mediaLibrary';
import { FinalizeUploadResponseData } from 'app/modules/mediaLibrary/types';

async function createRunningUpload(
  id: string,
  type: 'image' | 'video',
  file: File,
): Promise<RunningUpload> {
  function getPreview() {
    if (type === 'video') {
      return undefined;
    }

    return URL.createObjectURL(file);
  }

  return {
    id,
    base64Preview: await getPreview(),
    file,
    type,
    status: RunningUploadStatus.Pending,
    progress: undefined,
    cancelHandler: axios.CancelToken.source(),
  };
}

type CreateRunningUploadJobsParams = {
  files: { videos: File[]; images: File[] };
  pageId: number;
  albumId?: number;
  userAccountPublicId: string;
  userId: number;
  uploadSequence: UploadSequence;
};

export async function createUploadFromFiles(albumId: string, files: File[]) {
  const runningUploads: Record<
    string,
    {
      id: string;
      file: File;
      type: string;
      albumId: string;
      status: RunningUploadStatus;
      progress: number | undefined;
      error?: string;
      result?: ApiResponse<FinalizeUploadResponseData>;
    }
  > = {};

  for (const file of files) {
    const id = v4();
    runningUploads[id] = {
      id,
      file,
      type: file.type,
      albumId,
      status: RunningUploadStatus.Pending,
      progress: undefined,
    };
  }

  const jobs = Object.values(runningUploads).map(
    (runningUpload) => async () => {
      runningUpload.status = RunningUploadStatus.Uploading;

      try {
        runningUpload.result = await MediaLibraryApi.upload(
          {
            albumId: runningUpload.albumId,
            file: runningUpload.file,
          },
          {
            onUploadProgress: (progress: number) =>
              (runningUpload.progress = progress),
          },
        );
        runningUpload.status = RunningUploadStatus.Done;
      } catch (e) {
        Logger.error(e);

        runningUpload.status = RunningUploadStatus.Failed;
        runningUpload.error =
          (e instanceof ApiClientError && e?.userMessage) ||
          'Unable to upload. Please see the requirements and recommended sizes of files below.';
      }

      return runningUpload;
    },
  );

  return {
    jobs,
    runningUploads,
  };
}

/**
 * @deprecated use createUploadsFromFiles instead
 */
export async function createRunningUploadsFromFiles({
  files,
  albumId,
  pageId,
  userAccountPublicId,
  userId,
  uploadSequence,
}: CreateRunningUploadJobsParams): Promise<CreateRunningUploadsReturn> {
  const runningUploads: RunningUploadsRecord = {};

  for (const image of files.images) {
    const id = v4();
    runningUploads[id] = await createRunningUpload(id, 'image', image);
  }

  for (const video of files.videos) {
    const id = v4();
    runningUploads[id] = await createRunningUpload(id, 'video', video);
  }

  const jobs = Object.values(runningUploads).map(
    (runningUpload) => async () => {
      runningUpload.status = RunningUploadStatus.Uploading;

      try {
        if (runningUpload.type === 'image') {
          const response = await MediaApi.uploadImage({
            file: runningUpload.file,
            albumId,
            cancelToken: runningUpload.cancelHandler.token,
            onProgress: (progress: number) =>
              (runningUpload.progress = progress),
          });

          const uploadedImage = Object.values(response)[0];

          runningUpload.result = imageToMediaLibraryItem(uploadedImage);
        }

        if (runningUpload.type === 'video') {
          runningUpload.progress = 0;

          const res = await MediaApi.uploadVideo({
            file: runningUpload.file,
            cancelToken: runningUpload.cancelHandler.token,
            albumId,
            pageId,
            userAccountPublicId,
            userId,
            onProgress: (progress: number) =>
              (runningUpload.progress = progress),
          });

          runningUpload.result = unprocessedVideoToMediaLibraryItem(
            res.videoId,
          );
        }

        runningUpload.status = RunningUploadStatus.Done;
      } catch (e) {
        Logger.error(e);

        runningUpload.status = RunningUploadStatus.Failed;
        runningUpload.error =
          (e instanceof ApiClientError && e?.userMessage) ||
          'Unable to upload. Please see the requirements and recommended sizes of files below.';
      }

      return runningUpload;
    },
  );

  return {
    jobs: uploadSequence === 'lifo' ? jobs.reverse() : jobs,
    runningUploads,
  };
}
