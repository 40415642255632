import { Select } from '@kontentino/ui';
import React, { FC } from 'react';
import { MediaAsset } from '../types';

import Skeleton from 'react-loading-skeleton';
import AssetsWrapper from './new/asset/AssetsWrapper';
import AssetsFilters, { FiltersData } from './new/asset/AssetsFilters';

type Props = {
  assets: MediaAsset[];
  isLoading: boolean;
  onFiltersChange: (data: FiltersData) => void;
};
const Assets: FC<Props> = ({ assets, isLoading, onFiltersChange }) => {
  return (
    <div>
      <div className="tw-mb-6 tw-flex tw-flex-col">
        <h2 className="tw-mb-1 tw-text-base tw-font-semibold">Assets</h2>
        {isLoading && (
          <div className="tw-flex tw-gap-3">
            <Skeleton height={16} width={58} />
            <Skeleton height={16} width={58} />
            <Skeleton height={16} width={58} />
          </div>
        )}
        {assets.length > 0 && (
          <div className="tw-flex tw-gap-3">
            <span className="tw-text-sm tw-text-grayscale-100">138 Images</span>
            <span className="tw-text-sm tw-text-grayscale-100">84 Videos</span>
            <span className="tw-text-sm tw-text-grayscale-100">18 Gifs</span>
          </div>
        )}
      </div>

      <div className="tw-full tw-mb-6 tw-flex tw-flex-col tw-justify-between tw-gap-3 sm:tw-flex-row">
        <Select isDisabled className="tw-w-40" placeholder="All profiles" />

        <AssetsFilters onFiltersChange={onFiltersChange} />
      </div>

      <AssetsWrapper assets={assets} isLoading={isLoading} />
    </div>
  );
};

export default Assets;
