import { useState, useEffect, useMemo } from 'react';
import { MediaAlbum, MediaAsset } from '../types';
import { useInfiniteQuery } from 'react-query';
import { queryKey } from 'constants/queryKey';
import { MediaLibraryApi } from '../api/mediaLibrary';
import useDebounce from 'utils/hooks/useDebounce';
import { useToast } from 'app/hooks/useToast';
import Logger from 'utils/logger';

type FiltersData = {
  sortBy?: string;
  selectedType?: string;
  selectedSize?: string;
  selectedOrientation?: string;
};

export const useGetAssets = (
  search?: string,
  filtersData?: FiltersData,
  albumId?: string,
) => {
  const debouncedSearch = useDebounce(search, 500);
  Logger.log(search, filtersData, albumId);
  return useInfiniteQuery(
    [
      queryKey.allAssets(),
      debouncedSearch,
      filtersData?.sortBy,
      filtersData?.selectedType,
      filtersData?.selectedSize,
      filtersData?.selectedOrientation,
      albumId,
    ],
    ({ pageParam = 1 }) =>
      MediaLibraryApi.getAssets({
        limit: 20,
        album_id: albumId,
        page: pageParam,
        name: debouncedSearch ? debouncedSearch : undefined,
        sort_direction: filtersData?.sortBy === 'updated_at' ? 'desc' : 'asc',
        sort_by: filtersData?.sortBy,
        type:
          filtersData?.selectedType !== 'all'
            ? filtersData?.selectedType
            : undefined,
        size:
          filtersData?.selectedSize !== 'all'
            ? filtersData?.selectedSize
            : undefined,
        orientation:
          filtersData?.selectedOrientation !== 'all'
            ? filtersData?.selectedOrientation
            : undefined,
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalPages = Math.ceil(lastPage.pagination.totalCount / 20);
        const currentPage = allPages.length;
        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
      enabled: true,
    },
  );
};

export const useGetPaginatedAlbums = (
  search?: string,
  filtersData?: FiltersData,
) => {
  const debouncedSearch = useDebounce(search, 500);

  return useInfiniteQuery(
    [queryKey.allAlbums(), debouncedSearch, filtersData?.sortBy],
    async ({ pageParam = 1 }) => {
      const response = await MediaLibraryApi.getAllAlbums({
        limit: 20,
        page: pageParam,
        name: debouncedSearch?.length ? debouncedSearch : undefined,
        sort_direction: filtersData?.sortBy === 'updated_at' ? 'desc' : 'asc',
        sort_by: filtersData?.sortBy ? filtersData.sortBy : undefined,
      });
      return response;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalPages = Math.ceil(lastPage.pagination.totalCount / 20);
        const currentPage = allPages.length;
        return currentPage < totalPages ? currentPage + 1 : undefined;
      },
      enabled: true,
    },
  );
};

const useMediaLibrary = (
  search?: {
    assetsSearch?: string;
    albumsSearch?: string;
  },
  assetsFiltersData?: FiltersData,
  albumsFiltersData?: FiltersData,
  albumId?: string,
) => {
  const [assets, setAssets] = useState<MediaAsset[]>([]);
  const [albums, setAlbums] = useState<MediaAlbum[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  const paginatedAssets = useGetAssets(
    search?.assetsSearch,
    assetsFiltersData,
    albumId ?? '',
  );
  const paginatedAlbums = useGetPaginatedAlbums(
    search?.albumsSearch,
    albumsFiltersData,
  );

  useEffect(() => {
    if (paginatedAssets.data) {
      setAssets(paginatedAssets.data.pages.flatMap((page) => page.data));
    }
    if (paginatedAlbums.data) {
      setAlbums(paginatedAlbums.data.pages.flatMap((page) => page.data));
    }
    setLoading(paginatedAlbums.isLoading || paginatedAssets.isLoading);

    setLoading(paginatedAssets.isLoading || paginatedAlbums.isLoading);
    // setError(paginatedAssets.error || paginatedAlbums.error);
  }, [
    paginatedAssets.data,
    paginatedAlbums.data,
    paginatedAssets.isLoading,
    paginatedAlbums.isLoading,
    // paginatedAssets.error,
    paginatedAlbums.error,
    albumId,
  ]);

  const memoizedAssets = useMemo(() => assets, [assets]);
  const memoizedAlbums = useMemo(() => albums, [albums]);

  function submitSelectedAssetsToUpload(assetsIds: string[]) {
    toast(`Selected ${assetsIds.length} media`);
  }

  return {
    assets: memoizedAssets,
    albums: memoizedAlbums,
    paginatedAlbums,
    paginatedAssets,
    loading,
    error,
    submitSelectedAssetsToUpload,
  };
};

export default useMediaLibrary;
