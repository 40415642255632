import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { Button, Icon, Tooltip } from '@kontentino/ui';
import { queryKey } from 'constants/queryKey';
import routes from 'constants/routes';
import { useQuery } from 'react-query';
import { Link, useSearchParams } from 'react-router-dom';
import { MediaLibraryApi } from '../api/mediaLibrary';
import SubAlbumCreateModal from './new/SubAlbumCreateModal';
import { Fragment, useRef, useState } from 'react';
import SearchTextInput from 'app/components/SearchTextInput';
import AssetsWrapper from './new/asset/AssetsWrapper';
import Skeleton from 'react-loading-skeleton';
import AlbumsWrapper from './new/album/AlbumsWrapper';
import useDebounce from 'utils/hooks/useDebounce';
import useInfiniteScroll from 'utils/hooks/useInfiniteScroll';
import AssetsFilters from './new/asset/AssetsFilters';
import AlbumsActionDropdown from './new/album/AlbumsActionDropdown';
import useMediaLibrary from '../hooks/useMediaLibrary';
import Logger from 'utils/logger';
import { useUpload } from 'app/hooks/useUpload';

const AlbumDetail = () => {
  const [params] = useSearchParams();
  const albumId = params.get('id') ?? '';
  const [openSubAlbumModal, setOpenSubAlbumModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearch = useDebounce(searchValue, 500);
  const scrollableRef = useRef<HTMLDivElement>(null);
  const { upload } = useUpload();

  const { data: albumData, isLoading: areAlbumsLoading } = useQuery(
    queryKey.albumDetail(albumId),
    () => MediaLibraryApi.getAlbum(albumId),
  );

  const [filtersData, setFiltersData] = useState({
    sortBy: { key: 'name', label: 'Alphabetically' },
    selectedType: { value: 'all', label: 'All types' },
    selectedSize: { value: 'all', label: 'All sizes' },
    selectedOrientation: { value: 'all', label: 'All orientations' },
  });

  const handleSearchValueChange = (value: string) => {
    setSearchValue(value);
  };

  const { paginatedAssets } = useMediaLibrary(
    {
      assetsSearch: debouncedSearch,
    },
    {
      sortBy: filtersData.sortBy.key,
      selectedType: filtersData.selectedType.value,
      selectedSize: filtersData.selectedSize.value,
      selectedOrientation: filtersData.selectedOrientation.value,
    },
    undefined,
    albumId,
  );

  const handleFiltersChange = (newFilters: any) => {
    setFiltersData(newFilters);
  };

  const [infiniteRef] = useInfiniteScroll({
    loading: paginatedAssets.isLoading || paginatedAssets.isFetching,
    hasNextPage: !!paginatedAssets.hasNextPage,
    onLoadMore: () => {
      paginatedAssets.fetchNextPage();
    },
    disabled: !!debouncedSearch,
    root: scrollableRef.current,
    rootMargin: '0px 0px 400px 0px',
  });
  Logger.log('TuU', paginatedAssets);
  const allAssets =
    paginatedAssets.data?.pages.flatMap((page) => page.data) || [];
  const isLoading = paginatedAssets.isLoading || paginatedAssets.isFetching;

  // function handleSelectedAssetsIds(assetIds: string[]) {
  //   setSelectedAssetsIds(assetIds);
  // }

  const handleUpload = (files: File[]) => {
    upload(albumId, files);
  };

  return (
    <>
      <div className="tw-flex tw-h-full tw-flex-col tw-overflow-y-auto tw-p-6">
        <div className="tw-flex tw-items-center tw-justify-between">
          <div className="tw-mb-6 tw-flex tw-items-center tw-gap-2">
            <Link
              to={routes.ALBUMS}
              className="tw-text-lg tw-font-semibold tw-text-grayscale-180 hover:tw-text-primary-100"
            >
              Albums
            </Link>

            {!areAlbumsLoading &&
              albumData?.breadcrumbs?.map((breadcrumb) => (
                <Fragment key={breadcrumb.id}>
                  <Icon
                    icon={faChevronRight}
                    className="tw-text-grayscale-100"
                  />
                  <Link to={`${routes.ALBUM_DETAIL}?id=${breadcrumb.id}`}>
                    <span className="tw-text-lg tw-font-semibold tw-text-grayscale-180">
                      {breadcrumb.name}
                    </span>
                  </Link>
                </Fragment>
              ))}

            <>
              <Icon icon={faChevronRight} className="tw-text-grayscale-100" />
              <Link to={`${routes.ALBUM_DETAIL}?id=${albumData?.album.id}`}>
                <h2 className="tw-text-lg tw-font-semibold tw-text-grayscale-180">
                  {albumData?.album?.name}
                </h2>
              </Link>
            </>

            {areAlbumsLoading && !albumData && (
              <Skeleton height={20} width={200} />
            )}

            {albumData?.album?.description && (
              <Tooltip
                content={
                  <div className="tw-flex tw-flex-col">
                    <span className="tw-mb-1 tw-text-sm tw-font-semibold">
                      Description:
                    </span>
                    <p className="tw-text-sm">{albumData?.album.description}</p>
                  </div>
                }
              >
                <Icon
                  icon={faInfoCircle}
                  size={14}
                  className="tw-align-middle tw-text-grayscale-100"
                />
              </Tooltip>
            )}
          </div>
          <Button variant="primary" onClick={() => setOpenSubAlbumModal(true)}>
            + Album
          </Button>
        </div>

        {albumData?.albums && albumData.albums.length > 0 && (
          <h2 className="tw-text-base tw-font-semibold">Folders</h2>
        )}

        <AlbumsWrapper
          albums={albumData ? albumData.albums : []}
          isLoading={areAlbumsLoading}
          subalbums={true}
        />

        <div>
          <h2 className="tw-mb-1 tw-text-base tw-font-semibold">Assets</h2>
          <div className="tw-flex tw-gap-3">
            <span className="tw-text-sm tw-text-grayscale-100">138 Images</span>
            <span className="tw-text-sm tw-text-grayscale-100">84 Videos</span>
            <span className="tw-text-sm tw-text-grayscale-100">18 Gifs</span>
          </div>
        </div>

        <div className="tw-my-6 tw-flex tw-w-full tw-justify-between tw-gap-3">
          <SearchTextInput
            onClear={() => handleSearchValueChange('')}
            value={searchValue}
            onChange={(e) => handleSearchValueChange(e.target.value)}
            className="!tw-h-10"
            disabled={isLoading}
          />
          <AssetsFilters onFiltersChange={handleFiltersChange} />

          {albumData?.album && (
            <div className="tw-h-10 tw-w-10">
              <AlbumsActionDropdown album={albumData.album} isSubAlbum />
            </div>
          )}
        </div>
        <div ref={scrollableRef}>
          <AssetsWrapper
            assets={allAssets ?? []}
            isLoading={isLoading}
            onUpload={handleUpload}
          />
          <div ref={infiniteRef} />
        </div>
      </div>
      <SubAlbumCreateModal
        onClose={() => setOpenSubAlbumModal(false)}
        isOpen={openSubAlbumModal}
        parentAlbumId={albumId}
      />
    </>
  );
};

export default AlbumDetail;
