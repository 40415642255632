import React, { useState } from 'react';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import {
  BrandLogo,
  Checkbox,
  Chip,
  DropdownMenu,
  Icon,
  IconButton,
  SocialMediaIcon,
  SocialMediaIconProps,
} from '@kontentino/ui';
import PageTypeUtils from 'app/utils/pageType';
import { usePage } from 'modules/page/pageSelector';
import Logger from 'utils/logger';
import { stopPropagation } from 'utils/mouseEvent';
import { faFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';

type Props = {
  onFilterChange: (filters: any) => void;
};

const AlbumFilterPagesAndSocialMedia: React.FC<Props> = ({
  onFilterChange,
}) => {
  const { pages } = usePage();
  const [selectedSocialMedia, setSelectedSocialMedia] = useState<number[]>([]);
  const [selectedPages, setSelectedPages] = useState<
    {
      assigned_to: {
        model?: string;
        id: number;
        name: string;
        type: number;
      };
    }[]
  >([]);
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);

  const OPTIONS = [
    TYPE.FACEBOOK,
    TYPE.INSTAGRAM,
    TYPE.LINKEDIN,
    TYPE.TWITTER,
    TYPE.PINTEREST,
    TYPE.GOOGLE_MY_BUSINESS,
    TYPE.YOUTUBE,
    TYPE.TIKTOK,
    TYPE.THREADS,
  ];

  const handleFilterChange = () => {
    onFilterChange({
      selectedSocialMedia,
      selectedPages,
    });
  };

  Logger.log('selectedSocialMedia', selectedSocialMedia);
  const togglePageSelection = (
    page: { id: number; name: string; type: number; logo?: { src: string } },
    prevSelectedPages: {
      assigned_to: { model?: string; id: number; name: string; type: number };
    }[],
  ) => {
    let newSelectedPages;
    if (prevSelectedPages) {
      const isSelected = prevSelectedPages.some(
        (selectedPage) => selectedPage.assigned_to.id === page.id,
      );
      if (isSelected) {
        newSelectedPages = prevSelectedPages.filter(
          (selectedPage) => selectedPage.assigned_to.id !== page.id,
        );
      } else {
        newSelectedPages = [...prevSelectedPages, { assigned_to: page }];
      }
    } else {
      newSelectedPages = [{ assigned_to: page }];
    }
    handleFilterChange();
    return newSelectedPages;
  };

  return (
    <div className="tw-relative">
      <Chip variant="alert" className="tw-absolute -tw-right-1 -tw-top-2 ">
        {selectedPages.length + selectedSocialMedia.length}
      </Chip>
      <DropdownMenu.Root
        open={isMainMenuOpen}
        onOpenChange={(open) => {
          if (!open) return;
          setIsMainMenuOpen(true);
        }}
      >
        <DropdownMenu.Trigger>
          <IconButton variant="secondary" icon={<Icon icon={faFilter} />} />
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="tw-z-10"
            align="end"
            onInteractOutside={() => setIsMainMenuOpen(false)}
            onEscapeKeyDown={() => setIsMainMenuOpen(false)}
          >
            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger>
                <DropdownMenu.Item>
                  Assigned profile
                  <Icon className="tw-ml-auto" icon={faChevronRight} />
                </DropdownMenu.Item>
              </DropdownMenu.SubTrigger>
              <DropdownMenu.Portal>
                <DropdownMenu.SubContent>
                  {pages.map((page) => (
                    <DropdownMenu.Item
                      key={page.id}
                      className="tw-flex tw-items-center tw-gap-2"
                      onClick={stopPropagation(() =>
                        setSelectedPages((prev) =>
                          togglePageSelection(
                            {
                              ...page,
                              logo: page.logo ?? { src: '' },
                            },
                            prev,
                          ),
                        ),
                      )}
                    >
                      <Checkbox
                        size="small"
                        checked={selectedPages?.some(
                          (selectedPage) =>
                            selectedPage.assigned_to.id === page.id,
                        )}
                        onChange={() => null}
                      />
                      <BrandLogo
                        src={page.logo?.src}
                        size={16}
                        socialMediaType={
                          PageTypeUtils.getName(
                            page.type,
                          ) as SocialMediaIconProps['type']
                        }
                      />
                      {page.name}
                    </DropdownMenu.Item>
                  ))}
                </DropdownMenu.SubContent>
              </DropdownMenu.Portal>
            </DropdownMenu.Sub>
            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger>
                <DropdownMenu.Item>
                  Social media
                  <Icon className="tw-ml-auto" icon={faChevronRight} />
                </DropdownMenu.Item>
              </DropdownMenu.SubTrigger>
              <DropdownMenu.Portal>
                <DropdownMenu.SubContent>
                  {OPTIONS.map((page: number, index) => (
                    <DropdownMenu.Item
                      key={index}
                      className="tw-flex tw-items-center tw-gap-2"
                      onClick={() =>
                        setSelectedSocialMedia((prev) => {
                          const newSelectedSocialMedia = prev.includes(page)
                            ? prev.filter((item) => item !== page)
                            : [...prev, page];
                          handleFilterChange();
                          return newSelectedSocialMedia;
                        })
                      }
                    >
                      <Checkbox
                        size="small"
                        checked={selectedSocialMedia?.includes(page)}
                        onChange={() => null}
                      />
                      <SocialMediaIcon
                        type={
                          PageTypeUtils.getName(
                            page,
                          ) as SocialMediaIconProps['type']
                        }
                        size={16}
                        variant="plain"
                      />
                      {PageTypeUtils.getLabel(page)}
                    </DropdownMenu.Item>
                  ))}
                </DropdownMenu.SubContent>
              </DropdownMenu.Portal>
            </DropdownMenu.Sub>
            <DropdownMenu.Separator />
            <DropdownMenu.Item
              className="tw-flex tw-justify-end"
              onClick={() => {
                setSelectedSocialMedia([]);
                setSelectedPages([]);
                handleFilterChange();
              }}
            >
              Clear all
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
};

export default AlbumFilterPagesAndSocialMedia;
