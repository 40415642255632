import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import routes from 'constants/routes';
import { Button, Icon, TextLink } from '@kontentino/ui';
import AlbumCreateModal from './new/AlbumCreateModal';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import { PageThunk } from 'modules/page/pageActions';
import { useDispatch } from 'react-redux';
import SearchTextInput from 'app/components/SearchTextInput';
import useDebounce from 'utils/hooks/useDebounce';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import Assets from './Assets';
import AlbumsWrapper from './new/album/AlbumsWrapper';
import { FiltersData } from './new/asset/AssetsFilters';
import useMediaLibrary from '../hooks/useMediaLibrary';

interface Props {}

const MediaLibrary: FC<Props> = (props) => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const debouncedSearch = useDebounce(value, 800);
  const [filtersData, setFiltersData] = useState({
    sortBy: { key: 'name', label: 'Alphabetically' },
    selectedType: { value: 'all', label: 'All types' },
    selectedSize: { value: 'all', label: 'All sizes' },
    selectedOrientation: { value: 'all', label: 'All orientations' },
  });

  useEffectOnce(() => {
    dispatch(PageThunk.requestPages());
  });

  const { albums, loading: areAlbumsLoading } = useMediaLibrary({
    albumsSearch: debouncedSearch,
  });

  const { paginatedAssets } = useMediaLibrary(
    {
      assetsSearch: debouncedSearch,
    },
    {
      sortBy: filtersData.sortBy.key,
      selectedType: filtersData.selectedType.value,
      selectedSize: filtersData.selectedSize.value,
      selectedOrientation: filtersData.selectedOrientation.value,
    },
  );

  const allAssets =
    paginatedAssets.data?.pages.flatMap((page) => page.data) || [];

  const areAssetsLoading =
    paginatedAssets.isLoading || paginatedAssets.isFetching;

  const handleSearchFieldValueChange = (value: string) => {
    setValue(value);
  };

  const handleFiltersChange = (newFilters: FiltersData) => {
    setFiltersData(newFilters);
  };

  return (
    <>
      <div className="tw-flex tw-h-full tw-flex-col  tw-overflow-y-auto tw-p-6">
        <div className="w-full tw-mb-6 tw-flex tw-h-9 tw-items-center tw-justify-between">
          <h1 className="tw-text-lg tw-font-semibold">Media library</h1>
          <div className="tw-flex tw-items-center tw-justify-between tw-gap-3">
            <SearchTextInput
              onClear={() => handleSearchFieldValueChange('')}
              data-name="albums_search-field"
              data-cy="albums_search-field"
              value={value}
              onChange={(e) => {
                handleSearchFieldValueChange(e.target.value);
              }}
              className="!tw-h-10"
              disabled={areAlbumsLoading || areAssetsLoading}
            />
            <Button
              variant="primary"
              iconBefore={<Icon icon={faPlus} />}
              onClick={() => setOpenCreateModal(true)}
              size="medium"
            >
              Album
            </Button>
          </div>
        </div>

        <section className="tw-flex tw-flex-col">
          <h2 className=" tw-text-base tw-font-semibold">Albums</h2>

          <AlbumsWrapper
            albums={albums ? albums.slice(0, 8) : []}
            isLoading={areAlbumsLoading}
          />

          {albums.length > 8 && (
            <div className="tw-mt-4 tw-flex tw-self-end">
              <TextLink>
                <Link to={routes.ALBUMS}>All albums</Link>
              </TextLink>
            </div>
          )}
        </section>

        <section>
          <Assets
            assets={allAssets}
            isLoading={areAssetsLoading}
            onFiltersChange={handleFiltersChange}
          />
        </section>
      </div>
      {openCreateModal && (
        <AlbumCreateModal
          isOpen={openCreateModal}
          onClose={() => setOpenCreateModal(false)}
        />
      )}
    </>
  );
};

export default MediaLibrary;
