import { faFolder } from '@fortawesome/pro-regular-svg-icons/faFolder';
import { BrandLogo, Icon, SocialMediaIconProps } from '@kontentino/ui';
import PageTypeUtils from 'app/utils/pageType';
import routes from 'constants/routes';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { createSearchFromParams } from 'utils/url';
import { MediaAlbum } from '../../types';
import AlbumsActionDropdown from './album/AlbumsActionDropdown';

type AlbumCardProps = {
  album: MediaAlbum;
  isSubAlbum?: boolean;
};

const AlbumCard: FC<AlbumCardProps> = ({ album, isSubAlbum }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() =>
        navigate({
          pathname: routes.ALBUM_DETAIL,
          search: createSearchFromParams({
            id: album.id,
          }),
        })
      }
      className="tw-group tw-relative tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-gap-3 tw-rounded tw-bg-white tw-px-4 tw-py-2 tw-shadow-sm hover:tw-bg-primary-10"
    >
      <div className="tw-item-center tw-flex tw-gap-3">
        <div className="tw-relative tw-flex tw-items-center">
          {!!album.assigned_to && album.cover_image_url ? (
            <BrandLogo
              src={album.cover_image_url}
              size={24}
              socialMediaType={
                PageTypeUtils.getName(
                  album.assigned_to.type,
                ) as SocialMediaIconProps['type']
              }
            />
          ) : (
            <Icon size={20} className="tw-text-grayscale-100" icon={faFolder} />
          )}
        </div>
        <div className="tw-flex tw-flex-col">
          <span className="tw-text-md tw-font-semibold tw-text-grayscale-180 group-hover:tw-text-primary-100">
            {album.name}
          </span>
          <span className="tw-text-sm tw-text-grayscale-140">
            {/* TODO: plurals */}
            {album.stats.images_count} images, {album.stats.videos_count} videos
          </span>
        </div>
      </div>
      <div className="tw-h-6 tw-w-6">
        <AlbumsActionDropdown album={album} isSubAlbum={isSubAlbum} invisible />
      </div>
    </div>
  );
};

export default AlbumCard;
