import React, { FC, useState } from 'react';
import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import { faDownload } from '@fortawesome/pro-regular-svg-icons/faDownload';
import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons/faEllipsisVertical';
import { faFolder } from '@fortawesome/pro-regular-svg-icons/faFolder';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { DropdownMenu, IconButton, Icon, colors } from '@kontentino/ui';
import { stopPropagation } from 'utils/mouseEvent';
import AlbumAssignModal from '../AlbumAssignModal';
import AlbumDuplicateModal from '../AlbumDuplicateModal';
import AlbumEditModal from '../AlbumEditModal';
import { queryClient } from 'api/client';
import { useToast } from 'app/hooks/useToast';
import { MediaLibraryApi } from 'app/modules/mediaLibrary/api/mediaLibrary';
import { useMutation } from 'react-query';
import Popup from 'utils/popup';
import { MediaAlbum } from 'app/modules/mediaLibrary/types';
import clsx from 'clsx';

type Props = {
  album: MediaAlbum;
  isSubAlbum?: boolean;
  invisible?: boolean;
};

const AlbumsActionDropdown: FC<Props> = ({ album, isSubAlbum, invisible }) => {
  const toast = useToast();
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAssignModal, setOpenAssignModal] = useState(false);

  const setAsMasterAlbum = useMutation(
    () => MediaLibraryApi.setAsMasterAlbum(album.id),
    {
      onSuccess() {
        queryClient.invalidateQueries('allAlbums');
      },
      onError() {
        toast('Failed to set the album as master.', 'error');
      },
    },
  );

  const deleteAlbum = useMutation(() => MediaLibraryApi.deleteAlbum(album.id), {
    onSuccess() {
      queryClient.invalidateQueries('allAlbums');
    },
    onError() {
      toast('Unable to delete album.', 'error');
    },
  });

  async function onDeleteAlbum() {
    const { isConfirmed } = await Popup.confirm({
      title: ' Are you sure you want to delete this album?',
      text: 'Deleting this album will also remove all of its subfolders.',
      showCancelButton: true,
      confirmButtonColor: colors.danger.default,
      confirmButtonText: 'Delete',
      width: '400px',
    });

    if (isConfirmed) {
      deleteAlbum.mutate();
    }
  }

  async function onSetAsMaster() {
    const { isConfirmed } = await Popup.confirm({
      title: ' Are you sure you want to make this album a master album?',
      showCancelButton: true,
      confirmButtonText: 'Create',
      width: '400px',
    });

    if (isConfirmed) {
      setAsMasterAlbum.mutate();
    }
  }
  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <IconButton
            icon={<Icon icon={faEllipsisVertical} />}
            size="small"
            variant="secondary"
            onClick={stopPropagation()}
            className={clsx('tw-h-full tw-w-full', {
              'tw-opacity-0 group-hover:tw-opacity-100': invisible,
            })}
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content className="tw-z-10 tw-w-56">
          <span className="tw-ml-2 tw-text-sm tw-font-medium tw-text-grayscale-100">
            Album
          </span>
          <DropdownMenu.Item
            onClick={stopPropagation(() => setOpenEditModal(true))}
          >
            <Icon icon={faEdit} />
            Edit
          </DropdownMenu.Item>
          <DropdownMenu.Item
            onClick={stopPropagation(() => setOpenDuplicateModal(true))}
          >
            <Icon icon={faCopy} />
            Duplicate
          </DropdownMenu.Item>
          {!isSubAlbum && (
            <DropdownMenu.Item
              onClick={stopPropagation(() => setOpenAssignModal(true))}
            >
              <Icon icon={faFolder} />
              Assign
            </DropdownMenu.Item>
          )}
          {isSubAlbum && (
            <DropdownMenu.Item onClick={stopPropagation(() => onSetAsMaster())}>
              <Icon icon={faFolder} />
              Make a master album
            </DropdownMenu.Item>
          )}
          <DropdownMenu.Item onClick={stopPropagation()} disabled={true}>
            <Icon icon={faDownload} />
            Download all assets
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={stopPropagation(() => onDeleteAlbum())}>
            <Icon icon={faTrash} />
            Delete
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>

      {openAssignModal && (
        <AlbumAssignModal
          isOpen={openAssignModal}
          onClose={() => setOpenAssignModal(false)}
          initialData={{
            name: album.name,
            description: album.description || undefined,
            assigned_model_id: album.assigned_to?.id.toString() || '',
            assigned_model_name: 'Page',
            assigned_model_type: album.assigned_to?.type.toString() || '',
          }}
          albumId={album.id}
        />
      )}
      {openDuplicateModal && (
        <AlbumDuplicateModal
          onClose={() => setOpenDuplicateModal(false)}
          isOpen={openDuplicateModal}
          albumId={album.id}
          initialData={{
            name: album.name || '',
            description: album.description || undefined,
            assigned_model_id: album.assigned_to?.id.toString() || '',
            assigned_model_name: 'Page',
            assigned_model_type: album.assigned_to?.type.toString() || '',
          }}
        />
      )}
      {openEditModal && (
        <AlbumEditModal
          isOpen={openEditModal}
          onClose={() => setOpenEditModal(false)}
          initialData={{
            name: album.name,
            description: album.description || undefined,
            assigned_model_id: album.assigned_to?.id.toString() || '',
            assigned_model_name: 'Page',
            assigned_model_type: album.assigned_to?.type.toString() || '',
          }}
          albumId={album.id}
        />
      )}
    </>
  );
};

export default AlbumsActionDropdown;
