import { faFiles } from '@fortawesome/pro-regular-svg-icons/faFiles';
import { faGif } from '@fortawesome/pro-regular-svg-icons/faGif';
import { Icon } from '@kontentino/ui';
import { MediaAsset } from 'app/modules/mediaLibrary/types';
import React, { FC } from 'react';

interface Props {
  asset: MediaAsset;
}

const AssetTypeIndicator: FC<Props> = ({ asset }) => {
  function getIndicator() {
    // if (asset.mime_type === 'video') {
    //   return (
    //     <div className="tw-flex tw-items-center tw-gap-1">
    //       <Icon icon={faVideo} />

    //       {/* {asset.duration && (
    //         <span className="tw-text-sm">
    //           {TimeUtils.formatMilliSeconds(
    //             TimeUtils.calcMilliseconds.seconds(asset.duration),
    //           )}
    //         </span>
    //       )} */}
    //     </div>
    //   );
    // }

    if (asset.mime_type === 'image/gif') {
      return <Icon icon={faGif} />;
    }

    if (asset.mime_type === 'application/pdf') {
      return <Icon icon={faFiles} />;
    }

    return null;
  }

  const indicator = getIndicator();

  if (indicator !== null) {
    return (
      <div className="tw-mr-1  tw-mt-1 tw-flex tw-min-h-[1rem] tw-min-w-[1rem] tw-items-center tw-justify-center tw-rounded-sm tw-bg-grayscale-5 tw-px-1 tw-text-xs tw-font-semibold tw-text-grayscale-100">
        {indicator}
      </div>
    );
  }

  return null;
};

export default AssetTypeIndicator;
