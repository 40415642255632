import { useToast } from 'app/hooks/useToast';
import { useEffect, useState } from 'react';
import PQueue from 'p-queue';
import { RunningUpload, RunningUploadsRecord } from 'types/RunningUpload';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import { createUploads } from 'app/utils/runningUploads';
import { RunningUploadStatus } from 'constants/runningUpload';

export const useUpload = (config?: {
  onSuccessUpload?: (upload: RunningUpload) => void;
}) => {
  const [queue] = useState(
    new PQueue({
      concurrency: 1,
      autoStart: true,
    }),
  );
  const [isRunning, setIsRunning] = useState(false);
  const [runningUploads, setRunningUploads] = useState<RunningUploadsRecord>(
    {},
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const isQueueAtIdle = queue.size === 0 && queue.pending === 0;

      if (!isQueueAtIdle) {
        updateRunningUploads();
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRunning]);

  const toast = useToast();

  useEffectOnce(() => {
    queue.on('next', updateRunningUploads);
    queue.on('idle', () => setIsRunning(false));
    queue.on('add', () => setIsRunning(true));

    return () => {
      resetUploads();
    };
  });

  useEffect(() => {
    queue.on('completed', (res: RunningUpload) => {
      removeRunningUploads([res.id]);

      if (config?.onSuccessUpload) {
        config.onSuccessUpload(res);
      } else {
        toast('Media uploaded successfully', 'success');
      }

      if (res.status === RunningUploadStatus.Failed && res.error) {
        toast(res.error, 'error');
      }
    });

    return () => {
      queue.removeAllListeners('completed');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function removeRunningUploads(ids: string | string[]) {
    setRunningUploads((prev) => {
      const toDelete = Array.isArray(ids) ? ids : [ids];

      toDelete.forEach((id) => delete prev[id]);

      return { ...prev };
    });
  }

  function updateRunningUploads(newRunningUploads?: RunningUploadsRecord) {
    setRunningUploads((prev) => ({ ...newRunningUploads, ...prev }));
  }

  function resetUploads() {
    setRunningUploads((prev) => {
      Object.values(prev).forEach((upload) => upload.cancelHandler.cancel());
      queue.clear();

      return {};
    });

    setIsRunning(false);
  }

  const upload = async (albumId: string, files: File[]) => {
    const { runningUploads, jobs } = await createUploads(albumId, files);

    updateRunningUploads(runningUploads);
    queue.addAll(jobs);
  };

  return { upload, runningUploads };
};
