import { mergeTailwindClasses } from '@kontentino/ui';
import React, { FC } from 'react';
import MediaPreview from 'app/components/compoundMediaPreview';
import { MediaAsset } from '../../types';
import clsx from 'clsx';
import AssetTypeIndicator from './asset/AssetTypeIndicator';

export type Props = {
  asset: MediaAsset;
  className?: string;
  onClick?: () => void;
  onPreviewClick?: () => void;
  onSelect?: (assetId: string) => void;
  isSelected?: boolean;
};

const AssetCard: FC<Props> = ({
  className,
  asset,
  onClick,
  onPreviewClick,
  onSelect,
  isSelected,
}) => {
  //  TODO UserPermissionGate
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div className={mergeTailwindClasses(clsx('tw-relative', className))}>
      <MediaPreview.Root
        className="tw-aspect-[4/3] tw-h-auto tw-w-full"
        isSelected={isSelected}
        onSelect={onSelect ? () => onSelect(asset.id) : undefined}
        onClick={onClick}
      >
        <MediaPreview.Image
          className="tw-w-auto"
          src={asset.thumbnails[0].url}
          typeIndicator={
            asset.mime_type ? <AssetTypeIndicator asset={asset} /> : null
          }
        />
        <MediaPreview.Overlay onClick={onPreviewClick} />
        {/* <MediaPreview.Actions
          className={clsx('tw-gap-1', {
            'tw-opacity-100': isDropdownOpen,
          })}
        >
          <MediaPreview.ActionButton
            actionType="download"
            className="tw-rounded-md"
          />

          <AssetPreviewDropdown
            isOpen={isDropdownOpen}
            setIsDropdownOpen={setIsDropdownOpen}
            assetId={asset.id}
          />
          
        </MediaPreview.Actions> */}
      </MediaPreview.Root>
      <div className="tw-mt-1">
        {/* //TODO profile logo when it will be in BE  */}
        <p className="tw-text-sm tw-font-semibold">
          {asset?.original_filename}
        </p>
        <span className="tw-text-sm tw-font-regular">
          {asset?.usage_count}x used
        </span>
      </div>
    </div>
  );
};

export default AssetCard;
