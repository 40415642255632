import { MediaAlbum } from 'app/modules/mediaLibrary/types';
import React, { FC } from 'react';
import NoAlbumsFound from './NoAlbumsFound';
import Skeleton from 'react-loading-skeleton';
import ArrayUtils from 'app/utils/array';
import AlbumCard from '../AlbumCard';
import SimpleGrid from 'app/components/SimpleGrid';

type Props = {
  isLoading: boolean;
  albums: MediaAlbum[];
  subalbums?: boolean;
};
const AlbumsWrapper: FC<Props> = ({ albums, isLoading, subalbums = false }) => {
  const skeletons = ArrayUtils.generate(4);

  return (
    <div className="tw-my-6">
      {!isLoading && albums?.length === 0 && !subalbums && <NoAlbumsFound />}

      <SimpleGrid columns={4} gap={16}>
        {isLoading && skeletons.map((i) => <Skeleton height={52} key={i} />)}
        {!isLoading &&
          albums.map((album: MediaAlbum) => (
            <AlbumCard key={album.id} album={album} isSubAlbum={subalbums} />
          ))}
      </SimpleGrid>
    </div>
  );
};

export default AlbumsWrapper;
