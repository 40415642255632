import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { Button, DropdownMenu, Icon, TextInput } from '@kontentino/ui';
import React, { FC, useRef, useState } from 'react';
import AlbumCard from './new/AlbumCard';
import Skeleton from 'react-loading-skeleton';
import ArrayUtils from 'app/utils/array';
import AlbumCreateModal from './new/AlbumCreateModal';
import { useDispatch } from 'react-redux';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import { PageThunk } from 'modules/page/pageActions';
import SearchTextInput from 'app/components/SearchTextInput';
import useDebounce from 'utils/hooks/useDebounce';
import NoAlbumsFound from './new/album/NoAlbumsFound';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import useInfiniteScroll from 'utils/hooks/useInfiniteScroll';
import AlbumFilterPagesAndSocialMedia from './new/album/AlbumFilterPagesAndSocialMedia';
import useMediaLibrary from '../hooks/useMediaLibrary';
import SimpleGrid from 'app/components/SimpleGrid';

type Props = {};

const SORT_OPTIONS = [
  {
    key: 'name',
    label: 'Alphabetically',
  },
  {
    key: 'updated_at',
    label: 'Date of last update',
  },
];

const Albums: FC<Props> = () => {
  const [openCreateAlbumModal, setOpenCreateAlbumModal] = useState(false);
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const debouncedSearch = useDebounce(value, 500);
  const skeletons = ArrayUtils.generate(4);
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const scrollableRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterValues, setFilterValues] = useState({});

  useEffectOnce(() => {
    dispatch(PageThunk.requestPages());
  });

  const { paginatedAlbums, loading: isLoading } = useMediaLibrary(
    { albumsSearch: debouncedSearch },
    {
      sortBy: sortBy.key,
    },
  );

  const [infiniteRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !!paginatedAlbums.hasNextPage,
    onLoadMore: () => {
      paginatedAlbums.fetchNextPage();
    },
    disabled: !!debouncedSearch,
    root: scrollableRef.current,
    rootMargin: '0px 0px 400px 0px',
  });

  const handleSearchFieldValueChange = (value: string) => {
    setValue(value);
  };

  const allAlbums =
    paginatedAlbums.data?.pages.flatMap((page) => page.data) || [];

  return (
    <>
      <div className="tw-flex tw-h-full tw-flex-col tw-p-6">
        <h1 className="tw-text-lg tw-font-semibold">
          Albums {allAlbums ? `(${allAlbums.length})` : ''}
        </h1>
        <div className="tw-sticky tw-mt-6 tw-flex tw-justify-between">
          <div className="tw-flex tw-gap-2">
            <SearchTextInput
              onClear={() => handleSearchFieldValueChange('')}
              data-name="albums_search-field"
              data-cy="albums_search-field"
              value={value}
              onChange={(e) => {
                handleSearchFieldValueChange(e.target.value);
              }}
              className="!tw-h-10"
              disabled={isLoading}
            />

            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <Button variant="plain">
                  <TextInput
                    value={sortBy.label}
                    iconAfter={<Icon icon={faChevronDown} />}
                  />
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Portal>
                <DropdownMenu.Content className="tw-z-10" align="start">
                  {SORT_OPTIONS.map((type) => (
                    <DropdownMenu.Item
                      key={`sort-option-${type.key}`}
                      onClick={() => {
                        setSortBy(type);
                      }}
                      data-name={`sort_option-${type.label}`}
                      data-cy={`sort_option-${type.label}`}
                      className="tw-capitalize"
                    >
                      <span className="tw-font-medium">{type.label}</span>
                    </DropdownMenu.Item>
                  ))}
                </DropdownMenu.Content>
              </DropdownMenu.Portal>
            </DropdownMenu.Root>
          </div>
          <div className="tw-flex tw-items-center tw-gap-2">
            <AlbumFilterPagesAndSocialMedia onFilterChange={setFilterValues} />
            <Button
              variant="primary"
              iconBefore={<Icon icon={faPlus} />}
              onClick={() => setOpenCreateAlbumModal(true)}
              size="medium"
            >
              Album
            </Button>
          </div>
        </div>

        <div ref={scrollableRef}>
          <SimpleGrid gap={16} columns={4} className="tw-mt-6">
            {allAlbums.map((album) => (
              <AlbumCard key={album.id} album={album} />
            ))}

            {isLoading &&
              skeletons.map((i) => <Skeleton height={52} key={i} />)}

            <div ref={infiniteRef} />
          </SimpleGrid>
        </div>
        {allAlbums.length === 0 && !isLoading && <NoAlbumsFound />}
      </div>

      {openCreateAlbumModal && (
        <AlbumCreateModal
          isOpen={openCreateAlbumModal}
          onClose={() => setOpenCreateAlbumModal(false)}
        />
      )}
    </>
  );
};

export default Albums;
